































import {
    defineComponent,
    inject,
    ref,
    computed,
    onMounted,
} from '@vue/composition-api'

// Store
import { StoreKey as FormStoreKey } from '@/store/Form'
import { StoreKey as InputStoreKey } from '@/store/Input'

// Util
import { prepareField } from '@/ts/util/field'

type Props = {
    name: string
    value: string
    id: string
    inputClass: string
    labelClass: string
    separateLabel: string | boolean
    checked: string | boolean
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        value: { type: String, required: false },
        id: { type: String, required: false },
        inputClass: { type: String, required: false },
        labelClass: { type: String, required: false },
        separateLabel: { type: [String, Boolean], default: false },
        checked: { type: [String, Boolean], default: false },
    },
    setup(props: Props, { root }) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const commonFn = prepareField({
            type: 'checkbox_item',
            name: props.name,
            formStore,
        })

        const selected = ref<boolean>(false)
        const changeHandler = () => {
            if (inputStore.check_emitter.hasOwnProperty(props.name)) {
                inputStore.check_emitter[props.name]({
                    value: props.value,
                    selected: selected.value,
                })
            }
        }
        onMounted(() => {
            root.$nextTick(() => {
                if (props.checked === false) {
                    return
                }
                selected.value = true

                if (inputStore.check_emitter.hasOwnProperty(props.name)) {
                    inputStore.check_emitter[props.name]({
                        value: props.value,
                        selected: selected.value,
                    })
                }
            })
        })

        const classnames = computed(() => {
            const errors = inputStore.errors

            const result: { [key: string]: boolean } = {}
            ;(props.inputClass || '').split(' ').map((key) => {
                result[key] = true
            })
            result['is-invalid'] =
                errors.hasOwnProperty(props.name) && errors[props.name]

            return result
        })
        return { ...commonFn, selected, classnames, changeHandler }
    },
})
