































import {
    defineComponent,
    inject,
    ref,
    computed,
    onMounted,
} from '@vue/composition-api'

// Store
import { StoreKey as FormStoreKey } from '@/store/Form'
import { StoreKey as InputStoreKey } from '@/store/Input'

// Util
import { prepareField } from '@/ts/util/field'

type Props = {
    name: string
    value: string
    id: string
    inputClass: string
    labelClass: string
    separateLabel: boolean | string
    checked: string | boolean
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        value: { type: String, required: false },
        id: { type: String, required: false },
        inputClass: { type: String, required: false },
        labelClass: { type: String, required: false },
        separateLabel: { type: [Boolean, String], default: false },
        checked: { type: [String, Boolean], default: false },
    },
    setup(props: Props) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const commonFn = prepareField({
            type: 'radio_item',
            name: props.name,
            formStore,
        })

        const selected = ref<boolean>(false)
        const changeHandler = () => {
            if (selected.value) {
                inputStore.set_value(props.name, props.value)
            }

            if (inputStore.check_emitter.hasOwnProperty(props.name)) {
                inputStore.check_emitter[props.name]()
            }
        }
        onMounted(() => {
            if (props.checked === false) {
                return
            }
            selected.value = true
            inputStore.set_value(props.name, props.value)

            if (inputStore.check_emitter.hasOwnProperty(props.name)) {
                inputStore.check_emitter[props.name]()
            }
        })

        const classnames = computed(() => {
            const errors = inputStore.errors

            const result: { [key: string]: boolean } = {}
            ;(props.inputClass || '').split(' ').map((key) => {
                result[key] = true
            })
            result['is-invalid'] =
                errors.hasOwnProperty(props.name) && errors[props.name]

            return result
        })
        return { ...commonFn, selected, classnames, changeHandler }
    },
})
