
























import {
    defineComponent,
    inject,
    ref,
    computed,
    onMounted,
} from '@vue/composition-api'

// Store
import { StoreKey as FormStoreKey } from '@/store/Form'
import { StoreKey as InputStoreKey } from '@/store/Input'

// Util
import { prepareField } from '@/ts/util/field'
import { checkRequire } from '@/ts/util/validate'

type Props = {
    name: string
    required: boolean | string
    requiredMessage: string
    id: string
    classname: string
    multiple: string | boolean
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        required: { type: [String, Boolean], required: false },
        requiredMessage: { type: String, required: false },
        id: { type: String, required: false },
        classname: { type: String, required: false },
        multiple: { type: [String, Boolean], default: false },
    },
    setup(props: Props) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const commonFn = prepareField({
            type: 'select',
            name: props.name,
            inputStore,
            formStore,
        })

        const target = ref<HTMLSelectElement>(null)
        const updateHandler = () => {
            if (!target.value) {
                return
            }

            if (props.multiple) {
                let value = inputStore.get_value(props.name)
                if (!value) {
                    return
                } else if (!Array.isArray(value)) {
                    value = [value]
                }

                const options = target.value.querySelectorAll<
                    HTMLOptionElement
                >('option')
                const nodes = Array.prototype.slice.call(
                    options,
                    0
                ) as HTMLOptionElement[]

                nodes.map((option) => {
                    if (value.some((val) => option.value === val)) {
                        option.selected = true
                    }
                })
            } else {
                target.value.value = inputStore.get_value(props.name)
                if (!target.value.value) {
                    target.value.value = ''
                }
            }
        }
        onMounted(updateHandler)

        const isMultiple = computed(() => {
            return props.multiple ? true : false
        })

        const changeHandler = () => {
            if (!target.value) {
                return
            }

            if (props.multiple) {
                if (!inputStore.values.hasOwnProperty(props.name)) {
                    inputStore.values[props.name] = []
                }

                const values: string[] = []
                const options = target.value.querySelectorAll<
                    HTMLOptionElement
                >('option')
                const nodes = Array.prototype.slice.call(
                    options,
                    0
                ) as HTMLOptionElement[]

                nodes.map((option) => {
                    if (option.selected) {
                        values.push(option.value)
                    }
                })
                inputStore.set_value(props.name, values)
            } else {
                inputStore.set_value(props.name, target.value.value)
            }
        }

        const errors = ref<string[]>([])
        const blurHandler = () => {
            // バリデーション
            onValidate()
        }

        const onValidate = () => {
            const value = inputStore.get_value(props.name)

            // バリデーション
            let result = false
            errors.value = []
            const requiredCheck = checkRequire(value)
            if (props.required !== false) {
                if (!requiredCheck) {
                    result = true

                    const message = props.requiredMessage || '入力してください'
                    errors.value.push(message)
                }
            }

            inputStore.set_error(props.name, result)
            return result
        }

        const getValidateRule = () => {
            return {
                required: props.required ? true : false,
            }
        }

        const classnames = computed(() => {
            const errors = inputStore.errors

            const result: { [key: string]: boolean } = {}
            ;(props.classname || '').split(' ').map((key) => {
                result[key] = true
            })
            result['is-invalid'] =
                errors.hasOwnProperty(props.name) && errors[props.name]

            return result
        })
        return {
            ...commonFn,
            updateHandler,
            onValidate,
            getValidateRule,
            target,
            isMultiple,
            errors,
            classnames,
            changeHandler,
            blurHandler,
        }
    },
})
