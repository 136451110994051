var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fragment", [
    _c("textarea", {
      ref: "target",
      class: _vm.classnames,
      attrs: {
        name: _vm.name,
        id: _vm.id,
        placeholder: _vm.placeholder,
        cols: _vm.cols,
        rows: _vm.rows,
        disabled: _vm.readonly
      },
      on: { input: _vm.inputHandler, blur: _vm.blurHandler }
    }),
    _vm._v(" "),
    _vm.errors.length
      ? _c(
          "div",
          {
            staticClass: "invalid-feedback",
            staticStyle: { display: "block" }
          },
          _vm._l(_vm.errors, function(error, index) {
            return _c("div", { key: index }, [_vm._v(_vm._s(error))])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }