




import {
    defineComponent,
    onMounted,
    provide,
    reactive,
    toRefs,
} from '@vue/composition-api'
import axios from 'axios'

// Components
import FormWrap from '@/component/form/Wrap.vue'

// Store
import makeFormStore, {
    StoreKey as FormStoreKey,
    STEP_CONFIRM,
} from '@/store/Form'
import makeStore, { StoreKey as InputStoreKey } from '@/store/Input'
import makeErrorStore, { StoreKey as ErrorStoreKey } from '@/store/Error'

type Props = {
    formId: number
    basePath: string
    confirm: boolean
    result: boolean
}

const getComponentState = () => {
    const state = reactive({
        isLoaded: false,
    })

    return toRefs(state)
}

export default defineComponent({
    props: {
        formId: { type: Number, required: true },
        basePath: { type: String, required: true },
        confirm: { type: Boolean, required: true },
        result: { type: Boolean, required: true },
    },
    components: {
        FormWrap,
    },
    setup(props) {
        const formStore = makeFormStore()
        provide(FormStoreKey, formStore)

        const inputStore = makeStore(props.formId)
        provide(InputStoreKey, inputStore)

        const errorStore = makeErrorStore()
        provide(ErrorStoreKey, errorStore)

        const state = getComponentState()

        onMounted(async () => {
            formStore.base_path = props.basePath
            formStore.confirm = props.confirm
            formStore.result = props.result

            const apiUrl = (() => {
                if (props.result) {
                    return `${formStore.base_path}/api/result/${props.formId}`
                }
                if (props.confirm) {
                    return `${formStore.base_path}/api/confirm/${props.formId}`
                }
                return `${formStore.base_path}/api/form/${props.formId}`
            })()

            const src = document.createElement('script')
            src.async = true
            src.src = `${formStore.base_path}/script/${props.formId}`

            const targets = document.getElementsByTagName('script')
            if (targets.length && targets[0].parentNode) {
                targets[0].parentNode.insertBefore(src, targets[0])
            }

            ;(window as any)._form_system_submit = async () => {
                const response = await axios.get(apiUrl, {
                    withCredentials: true,
                })

                if (!response || typeof response.data !== 'object') {
                    window.alert('不明のエラーが発生しました')
                    throw Error('Failed to get form data')
                }

                if (!props.result) {
                    if (response.data.error) {
                        if (response.data.data.url_input) {
                            window.location.href = response.data.data.url_input
                        }
                        return
                    }

                    formStore.update(response.data.data)
                    inputStore.update(response.data.data.old)

                    if (formStore.is_enable_recaptcha !== 0) {
                        const src = document.createElement('script')
                        src.src = `https://www.google.com/recaptcha/api.js?render=${formStore.recaptcha_site_key}`

                        if (targets.length && targets[0].parentNode) {
                            targets[0].parentNode.insertBefore(src, targets[0])
                        }
                    }

                    state.isLoaded.value = true
                    if (props.confirm) {
                        formStore.step = STEP_CONFIRM

                        if (
                            typeof (window as any)._form_system_confirm ===
                            'function'
                        ) {
                            ;(window as any)._form_system_confirm(
                                response.data.data.old
                            )
                        }
                    }
                } else {
                    const formData = response.data.data
                    const oldData = formData.hasOwnProperty('data')
                        ? formData.data
                        : {}
                    if (formData.has_pardot) {
                        // const src = (() => {
                        //     const param = Object.keys(oldData)
                        //         .map(
                        //             (k) =>
                        //                 encodeURIComponent(k) +
                        //                 '=' +
                        //                 encodeURIComponent(oldData[k])
                        //         )
                        //         .join('&')

                        //     if (formData.pardot_endpoint.match(/\?/)) {
                        //         return `${formData.pardot_endpoint}&${param}`
                        //     } else {
                        //         return `${formData.pardot_endpoint}?${param}`
                        //     }
                        // })()
                        const src = (() => {
                            if (formData.url_input.match(/\?/)) {
                                return `${formData.url_input}&pi_list_email=${oldData._form_system_client_email}`
                            } else {
                                return `${formData.url_input}?pi_list_email=${oldData._form_system_client_email}`
                            }
                        })()

                        const iframe = document.createElement('iframe')
                        iframe.width = '1'
                        iframe.height = '1'
                        iframe.src = src
                        document.body.appendChild(iframe)
                    }

                    if (
                        typeof (window as any)._form_system_result ===
                        'function'
                    ) {
                        ;(window as any)._form_system_result(
                            response.data.data.data
                        )
                    }
                }
            }
        })

        return { ...state }
    },
})
