var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      ref: "formWrap",
      staticClass: "p-form-system",
      attrs: { action: "", method: "post", novalidate: "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitHandler($event)
        }
      }
    },
    [
      _c(_vm.compiled, { tag: "component" }),
      _vm._v(" "),
      _vm.errorStore.messages.length
        ? _c(
            "div",
            { staticClass: "alert alert-danger mt-4" },
            _vm._l(_vm.errorStore.messages, function(message, index) {
              return _c("div", { key: index }, [
                _vm._v("\n            " + _vm._s(message) + "\n        ")
              ])
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }