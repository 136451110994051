import { StoreType as InputStoreType } from '@/store/Input'
import { STEP_CONFIRM, StoreType as FormStoreType } from '@/store/Form'
import { computed } from '@vue/composition-api'

export const prepareField = ({
    type,
    name,
    formStore,
    inputStore = null,
}: {
    type: string
    name: string
    formStore: FormStoreType
    inputStore?: InputStoreType | null
}) => {
    const get_type = () => {
        return type
    }
    const get_name = () => {
        return name
    }
    const get_value = () => {
        if (!inputStore || !inputStore.values.hasOwnProperty(name)) {
            return ''
        }
        return inputStore.values[name]
    }

    const readonly = computed(() => formStore.step === STEP_CONFIRM)

    return { get_type, get_name, get_value, readonly }
}
