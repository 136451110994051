var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fragment",
    [
      _vm.separateLabel !== false
        ? [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selected,
                  expression: "selected"
                }
              ],
              class: _vm.classnames,
              attrs: {
                type: "radio",
                name: _vm.name,
                id: _vm.id,
                disabled: _vm.readonly
              },
              domProps: {
                value: _vm.value,
                checked: _vm._q(_vm.selected, _vm.value)
              },
              on: {
                change: [
                  function($event) {
                    _vm.selected = _vm.value
                  },
                  _vm.changeHandler
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              { class: _vm.labelClass, attrs: { for: _vm.id } },
              [_vm._t("default")],
              2
            )
          ]
        : _c(
            "label",
            { class: _vm.labelClass },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selected,
                    expression: "selected"
                  }
                ],
                class: _vm.classnames,
                attrs: {
                  type: "radio",
                  name: _vm.name,
                  id: _vm.id,
                  disabled: _vm.readonly
                },
                domProps: {
                  value: _vm.value,
                  checked: _vm._q(_vm.selected, _vm.value)
                },
                on: {
                  change: [
                    function($event) {
                      _vm.selected = _vm.value
                    },
                    _vm.changeHandler
                  ]
                }
              }),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }