import { InjectionKey, reactive } from '@vue/composition-api'

export type InputType = {
    form_id: number
    values: { [key: string]: any }
    errors: { [key: string]: boolean }
    check_emitter: { [key: string]: Function }
    accept_length: number
    accepted_length: number
}

export type RequestType = {
    form_id?: number
    name: string
    tel: string
    email: string
    howto: string
    zip: string
    address: string
    ipad: string
    shop: string
    shop_other: string
    date: string
    time: string
    request1: string
    request2: string
    request3: string
    request_other: string
}

export default function store(form_id: number) {
    const state = reactive<InputType>({
        form_id,
        values: {},
        errors: {},
        check_emitter: {},
        accept_length: 0,
        accepted_length: 0,
    })

    return {
        // form_id
        get form_id() {
            return state.form_id
        },

        // values
        get values() {
            return state.values
        },
        set values(values) {
            state.values = { ...values }
        },
        get_value(name) {
            if (!state.values.hasOwnProperty(name)) {
                return null
            }
            return state.values[name]
        },
        set_value(name, value) {
            const newValue: { [key: string]: string } = {}
            newValue[name] = value
            state.values = { ...state.values, ...newValue }
        },

        // errors
        get errors() {
            return state.errors
        },
        set errors(errors) {
            state.errors = { ...errors }
        },
        get_error(name) {
            if (!state.errors.hasOwnProperty(name)) {
                return false
            }
            return state.errors[name]
        },
        set_error(name, flag: boolean) {
            const newValue: { [key: string]: boolean } = {}
            newValue[name] = flag
            state.errors = { ...state.errors, ...newValue }
        },

        // Check Emitter
        get check_emitter() {
            return state.check_emitter
        },
        set check_emitter(check_emitter) {
            state.check_emitter = { ...check_emitter }
        },

        // accept_length
        get accept_length() {
            return state.accept_length
        },
        add_accept_length(num = 1) {
            state.accept_length += num
        },
        sub_accept_length(num = 1) {
            state.accept_length -= num
        },

        // accepted_length
        get accepted_length() {
            return state.accepted_length
        },
        add_accepted_length(num = 1) {
            state.accepted_length += num
        },
        sub_accepted_length(num = 1) {
            state.accepted_length -= num
        },

        // is_submitable
        get is_submitable() {
            const errorLength = Object.keys(state.errors).filter((key) => {
                return state.errors[key]
            }).length

            return (
                state.accepted_length >= state.accept_length && errorLength <= 0
            )
        },

        update(values: { [key: string]: any } | null) {
            if (!values) {
                return
            }

            state.values = { ...state.values, ...values }
        },

        updateNegative(values: { [key: string]: any } | null) {
            if (!values) {
                return
            }

            state.values = { ...values, ...state.values }
        },
    }
}

export type StoreType = ReturnType<typeof store>
export const StoreKey: InjectionKey<StoreType> = Symbol('InputType')
