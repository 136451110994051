













import {
    defineComponent,
    inject,
    ref,
    computed,
    onMounted,
} from '@vue/composition-api'

// Store
import { StoreKey as FormStoreKey } from '@/store/Form'
import { StoreKey as InputStoreKey } from '@/store/Input'

// Util
import { prepareField } from '@/ts/util/field'

type Props = {
    name: string
    required: boolean | string
    requiredMessage: string
    id: string
    classname: string
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        required: { type: [String, Boolean], required: false },
        requiredMessage: { type: String, required: false },
        id: { type: String, required: false },
        classname: { type: String, required: false },
    },
    setup(props: Props) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const commonFn = prepareField({
            type: 'radio',
            name: props.name,
            inputStore,
            formStore,
        })

        const target = ref<HTMLSelectElement>(null)
        const updateHandler = () => {
            if (!target.value) {
                return
            }

            const value = inputStore.get_value(props.name)
            if (!value) {
                return
            }

            const radios = target.value.querySelectorAll<HTMLOptionElement>(
                'input[type="radio"]'
            )
            const nodes = Array.prototype.slice.call(
                radios,
                0
            ) as HTMLInputElement[]

            nodes.map((radio) => {
                if (radio.value === value) {
                    radio.checked = true
                }
            })
        }
        onMounted(updateHandler)

        const errors = ref<string[]>([])
        const checkedHandler = () => {
            // バリデーション
            onValidate()
        }
        inputStore.check_emitter[props.name] = checkedHandler

        const onValidate = () => {
            // バリデーション
            let result = false
            errors.value = []
            const val = inputStore.get_value(props.name)
            if (props.required !== false) {
                if (
                    (!Array.isArray(val) && !val) ||
                    (Array.isArray(val) && !val.length)
                ) {
                    result = true
                    const message = props.requiredMessage || '入力してください'
                    errors.value.push(message)
                }
            }
            inputStore.set_error(props.name, result)
            return result
        }

        const getValidateRule = () => {
            return {
                required: props.required ? true : false,
            }
        }

        const classnames = computed(() => {
            const errors = inputStore.errors

            const result: { [key: string]: boolean } = {}
            ;(props.classname || '').split(' ').map((key) => {
                result[key] = true
            })
            result['is-invalid'] =
                errors.hasOwnProperty(props.name) && errors[props.name]

            return result
        })
        return {
            ...commonFn,
            target,
            errors,
            updateHandler,
            onValidate,
            getValidateRule,
            classnames,
        }
    },
})
