var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "button",
        {
          class: _vm.classname,
          attrs: {
            type: "button",
            name: _vm.name,
            id: _vm.id,
            disabled: !_vm.isSubmitable
          },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.clickHandler($event)
            }
          }
        },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }