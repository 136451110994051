const defaultMojisyu = {
    ZE: { start: 0xff01, end: 0xff5e }, // 全角英数
    HE: { start: 0x0021, end: 0x007e }, // 半角英数
    HG: { start: 0x3041, end: 0x3096 }, // ひらがな
    KK: { start: 0x30a1, end: 0x30f6 }, // カタカナ

    HS: { regexp: /(\s|\u00A0)/g, list: ['\u0020', '\u00A0'] }, // 半角スペース
    ZS: { regexp: /(\u3000)/g, list: ['　', '　'] }, //全角スペース

    HK: {
        regexp: /([\uff66-\uff9c]\uff9e)|([\uff8a-\uff8e]\uff9f)|([\uff61-\uff9f])/g, // 半角カナ
        list: [
            '｡',
            '｢',
            '｣',
            '､',
            '･',
            'ｦ',
            'ｧ',
            'ｨ',
            'ｩ',
            'ｪ',
            'ｫ',
            'ｬ',
            'ｭ',
            'ｮ',
            'ｯ',
            'ｰ',
            'ｱ',
            'ｲ',
            'ｳ',
            'ｴ',
            'ｵ',
            'ｶ',
            'ｷ',
            'ｸ',
            'ｹ',
            'ｺ',
            'ｻ',
            'ｼ',
            'ｽ',
            'ｾ',
            'ｿ',
            'ﾀ',
            'ﾁ',
            'ﾂ',
            'ﾃ',
            'ﾄ',
            'ﾅ',
            'ﾆ',
            'ﾇ',
            'ﾈ',
            'ﾉ',
            'ﾊ',
            'ﾋ',
            'ﾌ',
            'ﾍ',
            'ﾎ',
            'ﾏ',
            'ﾐ',
            'ﾑ',
            'ﾒ',
            'ﾓ',
            'ﾔ',
            'ﾕ',
            'ﾖ',
            'ﾗ',
            'ﾘ',
            'ﾙ',
            'ﾚ',
            'ﾛ',
            'ﾜ',
            'ﾝ',
            'ﾞ',
            'ﾟ',
            'ｦﾞ',
            'ｳﾞ',
            'ｶﾞ',
            'ｷﾞ',
            'ｸﾞ',
            'ｹﾞ',
            'ｺﾞ',
            'ｻﾞ',
            'ｼﾞ',
            'ｽﾞ',
            'ｾﾞ',
            'ｿﾞ',
            'ﾀﾞ',
            'ﾁﾞ',
            'ﾂﾞ',
            'ﾃﾞ',
            'ﾄﾞ',
            'ﾊﾞ',
            'ﾊﾟ',
            'ﾋﾞ',
            'ﾋﾟ',
            'ﾌﾞ',
            'ﾌﾟ',
            'ﾍﾞ',
            'ﾍﾟ',
            'ﾎﾞ',
            'ﾎﾟ',
            'ﾜﾞ',
        ],
    },
    ZK: {
        regexp: /([\u3001-\u30fc])/g, //全角カナ (半角カナ変換用)
        list: [
            '。',
            '「',
            '」',
            '、',
            '・',
            'ヲ',
            'ァ',
            'ィ',
            'ゥ',
            'ェ',
            'ォ',
            'ャ',
            'ュ',
            'ョ',
            'ッ',
            'ー',
            'ア',
            'イ',
            'ウ',
            'エ',
            'オ',
            'カ',
            'キ',
            'ク',
            'ケ',
            'コ',
            'サ',
            'シ',
            'ス',
            'セ',
            'ソ',
            'タ',
            'チ',
            'ツ',
            'テ',
            'ト',
            'ナ',
            'ニ',
            'ヌ',
            'ネ',
            'ノ',
            'ハ',
            'ヒ',
            'フ',
            'ヘ',
            'ホ',
            'マ',
            'ミ',
            'ム',
            'メ',
            'モ',
            'ヤ',
            'ユ',
            'ヨ',
            'ラ',
            'リ',
            'ル',
            'レ',
            'ロ',
            'ワ',
            'ン',
            '゛',
            '゜',
            'ヺ',
            'ヴ',
            'ガ',
            'ギ',
            'グ',
            'ゲ',
            'ゴ',
            'ザ',
            'ジ',
            'ズ',
            'ゼ',
            'ゾ',
            'ダ',
            'ヂ',
            'ヅ',
            'デ',
            'ド',
            'バ',
            'パ',
            'ビ',
            'ピ',
            'ブ',
            'プ',
            'ベ',
            'ペ',
            'ボ',
            'ポ',
            'ヷ',
        ],
    },
}

export default defaultMojisyu
