































import { defineComponent, inject, ref, onMounted } from '@vue/composition-api'

// Store
import { StoreKey as FormStoreKey } from '@/store/Form'
import { StoreKey as InputStoreKey } from '@/store/Input'

// Util
import { prepareField } from '@/ts/util/field'

type Props = {
    name: string
    id: string
    classname: string
    inputClass: string
    labelClass: string
    separateLabel: string | boolean
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        id: { type: String, required: false },
        classname: { type: String, required: false },
        inputClass: { type: String, required: false },
        labelClass: { type: String, required: false },
        separateLabel: { type: [String, Boolean], default: false },
    },
    setup(props: Props) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }
        inputStore.add_accept_length()

        const commonFn = prepareField({
            type: 'acceptance',
            name: props.name,
            formStore,
            inputStore,
        })

        const selected = ref<boolean>(false)
        const changeHandler = () => {
            inputStore.set_value(props.name, selected.value ? 1 : 0)

            if (selected.value) {
                inputStore.add_accepted_length()
            } else {
                inputStore.sub_accepted_length()
            }
        }

        const updateHandler = () => {
            if (!inputStore.get_value(props.name)) {
                return
            }

            inputStore.add_accepted_length()
            selected.value = true
        }
        onMounted(updateHandler)

        return { ...commonFn, selected, changeHandler }
    },
})
