













import {
    defineComponent,
    ref,
    onMounted,
    inject,
    computed,
} from '@vue/composition-api'
import Dropzone from 'dropzone'

// Store
import { StoreKey as FormStoreKey } from '@/store/Form'
import { StoreKey as InputStoreKey } from '@/store/Input'

// Util
import { prepareField } from '@/ts/util/field'

type Props = {
    name: string
    required: boolean | string
    requiredMessage: string
    id: string
    classname: string
    limit_size: string
    limit_type: string
    multiple: boolean | string
    multiple_max: string
    dictDefaultMessage: string
    dictFallbackMessage: string
    dictFallbackText: string
    dictFileTooBig: string
    dictInvalidFileType: string
    dictResponseError: string
    dictCancelUpload: string
    dictUploadCanceled: string
    dictCancelUploadConfirmation: string
    dictRemoveFile: string
    dictMaxFilesExceeded: string
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        required: { type: [String, Boolean], required: false },
        requiredMessage: { type: String, required: false },
        id: { type: String, required: false },
        classname: { type: String, required: false },
        limit_size: { type: String, required: false },
        limit_type: { type: String, required: false },
        multiple: { type: [String, Boolean], default: false },
        multiple_max: { type: String, required: false },
        dictDefaultMessage: {
            type: String,
            required: false,
            default: 'ファイルをドロップ、またはクリックしてアップロード',
        },
        dictFallbackMessage: {
            type: String,
            required: false,
            default: 'ご利用のブラウザのバージョンはサポートしていません',
        },
        dictFallbackText: {
            type: String,
            required: false,
            default: '下記のフォームをご利用ください',
        },
        dictFileTooBig: {
            type: String,
            required: false,
            default:
                'ファイルサイズが大きすぎます ({{filesize}}MiB) / 最大サイズ: {{maxFilesize}}MiB',
        },
        dictInvalidFileType: {
            type: String,
            required: false,
            default: '選択したファイル形式は許可されていません',
        },
        dictResponseError: {
            type: String,
            required: false,
            default:
                'サーバエラーが発生しました(ステータスコード {{statusCode}})',
        },
        dictCancelUpload: {
            type: String,
            required: false,
            default: 'キャンセル',
        },
        dictUploadCanceled: {
            type: String,
            required: false,
            default: 'キャンセルされました',
        },
        dictCancelUploadConfirmation: {
            type: String,
            required: false,
            default: 'キャンセルしてもよろしいですか？',
        },
        dictRemoveFile: {
            type: String,
            required: false,
            default: '削除',
        },
        dictMaxFilesExceeded: {
            type: String,
            required: false,
            default: 'これ以上ファイルを追加することはできません',
        },
    },
    setup(props: Props) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const commonFn = prepareField({
            type: 'file_uploader',
            name: props.name,
            inputStore,
            formStore,
        })

        const target = ref<HTMLInputElement>(null)
        const dropzone = ref<Dropzone>(null)
        const errors = ref<string[]>([])

        const onValidate = () => {
            if (!dropzone.value) {
                return
            }

            // バリデーション
            let result = false
            errors.value = []
            if (props.required !== false) {
                if (!dropzone.value.files.length) {
                    result = true

                    const message = props.requiredMessage || '選択してください'
                    errors.value.push(message)
                }
            }
            inputStore.set_error(props.name, result)
            return result
        }

        const getValidateRule = () => {
            return {
                required: props.required ? true : false,
            }
        }

        onMounted(() => {
            if (target.value) {
                const options: Dropzone.DropzoneOptions = {
                    url: `${formStore.base_path}/api/upload`,
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    dictDefaultMessage: props.dictDefaultMessage,
                    dictFallbackMessage: props.dictFallbackMessage,
                    dictFallbackText: props.dictFallbackText,
                    dictFileTooBig: props.dictFileTooBig,
                    dictInvalidFileType: props.dictInvalidFileType,
                    dictResponseError: props.dictResponseError,
                    dictCancelUpload: props.dictCancelUpload,
                    dictUploadCanceled: props.dictUploadCanceled,
                    dictCancelUploadConfirmation:
                        props.dictCancelUploadConfirmation,
                    dictRemoveFile: props.dictRemoveFile,
                    dictRemoveFileConfirmation: undefined,
                    dictMaxFilesExceeded: props.dictMaxFilesExceeded,
                    withCredentials: true,
                }

                if (props.limit_size) {
                    options.maxFilesize = parseInt(props.limit_size)
                }
                if (props.limit_type) {
                    options.acceptedFiles = props.limit_type
                }

                options.maxFiles = 1
                if (props.multiple) {
                    options.maxFiles = parseInt(props.multiple_max)
                }

                dropzone.value = new Dropzone(target.value, options)
                dropzone.value.on('sending', (_file, _xhr, formData) => {
                    if (props.limit_size) {
                        formData.append('limit_size', props.limit_size)
                    }
                    if (props.limit_type) {
                        formData.append('limit_type', props.limit_type)
                    }
                })
            }
        })

        const classnames = computed(() => {
            const errors = inputStore.errors

            const result: { [key: string]: boolean } = {}
            ;(props.classname || '').split(' ').map((key) => {
                result[key] = true
            })
            result['is-invalid'] =
                errors.hasOwnProperty(props.name) && errors[props.name]

            return result
        })
        return {
            target,
            dropzone,
            errors,
            classnames,
            ...commonFn,
            onValidate,
            getValidateRule,
        }
    },
})
