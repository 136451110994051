






import { defineComponent, computed, inject } from '@vue/composition-api'

// Store
import { StoreKey as InputStoreKey } from '@/store/Input'

type Props = {
    name: string
    target?: string | boolean | string[]
}

export default defineComponent({
    props: {
        name: { type: String, required: false },
        target: { type: [String, Boolean], default: false },
    },
    setup(props: Props) {
        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const isShow = computed(() => {
            if (
                !props.name ||
                !inputStore.values.hasOwnProperty(props.name) ||
                !inputStore.values[props.name]
            ) {
                return false
            }

            const keyValue = inputStore.values[props.name]
            const targetValue = (() => {
                if (Array.isArray(props.target)) {
                    return props.target
                } else if (typeof props.target === 'string') {
                    return props.target.split(',')
                } else {
                    return []
                }
            })()

            if (Array.isArray(keyValue)) {
                if (!keyValue.length) {
                    return false
                }

                if (targetValue.length) {
                    return keyValue.some((val) =>
                        targetValue.some((v) => val + '' === v + '')
                    )
                }
            }

            if (targetValue.length) {
                return targetValue.some((val) => val + '' === keyValue + '')
            }

            return true
        })

        return { isShow }
    },
})
