






















import {
    defineComponent,
    ref,
    onMounted,
    inject,
    computed,
} from '@vue/composition-api'
import flatpickr from 'flatpickr'

// Store
import { StoreKey as FormStoreKey } from '@/store/Form'
import { StoreKey as InputStoreKey } from '@/store/Input'

// Util
import { prepareField } from '@/ts/util/field'
import { checkRequire } from '@/ts/util/validate'

type Props = {
    name: string
    required: boolean | string
    requiredMessage: string
    id: string
    classname: string
    placeholder: string
    limit_week: string
    limit_date: string
    min_date: string
    max_date: string
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        required: { type: [String, Boolean], required: false },
        requiredMessage: { type: String, required: false },
        id: { type: String, required: false },
        classname: { type: String, required: false },
        placeholder: { type: String, required: false },
        limit_week: { type: String, required: false },
        limit_date: { type: String, required: false },
        min_date: { type: String, required: false },
        max_date: { type: String, required: false },
    },
    setup(props: Props) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const commonFn = prepareField({
            type: 'date_picker',
            name: props.name,
            inputStore,
            formStore,
        })

        const target = ref<HTMLInputElement>(null)
        const picker = ref<flatpickr.Instance>(null)
        const updateHandler = () => {
            if (!target.value) {
                return
            }

            target.value.value = inputStore.get_value(props.name)
        }
        onMounted(updateHandler)

        const errors = ref<string[]>([])
        const blurHandler = () => {
            onValidate()
        }

        const onValidate = () => {
            const value = inputStore.get_value(props.name)

            // バリデーション
            let result = false
            errors.value = []
            const requiredCheck = checkRequire(value)
            if (props.required !== false) {
                if (!requiredCheck) {
                    result = true

                    const message = props.requiredMessage || '入力してください'
                    errors.value.push(message)
                }
            }
            inputStore.set_error(props.name, result)
            return result
        }

        const getValidateRule = () => {
            return {
                required: props.required ? true : false,
            }
        }

        onMounted(() => {
            if (target.value) {
                const limitDate = props.limit_date
                    ? props.limit_date.split(',')
                    : []
                const limitWeek = props.limit_week
                    ? props.limit_week.split(',')
                    : []

                picker.value = flatpickr(target.value, {
                    // clickOpens: false,
                    dateFormat: 'Y年m月d日',
                    disableMobile: true,
                    disable: [
                        ...limitDate.map((el) => el.trim()),
                        (date) => {
                            const day = date.getDay() + ''
                            return limitWeek.some((el) => el.trim() == day)
                        },
                    ],
                    minDate: props.min_date,
                    maxDate: props.max_date,
                    onChange: (_selectedDates, currentDateString) => {
                        inputStore.set_value(props.name, currentDateString)
                        blurHandler()
                    },
                })
            }
        })

        const classnames = computed(() => {
            const errors = inputStore.errors

            const result: { [key: string]: boolean } = {}
            ;(props.classname || '').split(' ').map((key) => {
                result[key] = true
            })
            result['is-invalid'] =
                errors.hasOwnProperty(props.name) && errors[props.name]

            return result
        })
        return {
            target,
            errors,
            classnames,
            updateHandler,
            blurHandler,
            ...commonFn,
            onValidate,
            getValidateRule,
        }
    },
})
