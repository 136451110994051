import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
import isAlphanumeric from 'validator/lib/isAlphanumeric'
import matches from 'validator/lib/matches'

export const checkEmail = (string: string | null) => {
    if ((string || '').match(/\s/)) {
        return false
    }

    return isEmail(string || '')
}

export const checkRequire = (value: string | any[] | null) => {
    if (Array.isArray(value)) {
        return value.length > 0
    } else {
        return !isEmpty(value || '')
    }
}

export const checkAlphanumeric = (string: string | null) => {
    return isAlphanumeric(string || '')
}

export const checkHiragana = (string: string | null) => {
    /* eslint-disable-next-line */
    return matches(string || '', /^[ぁ-ゞ 　〜ー−]+$/m)
}

export const checkKatakana = (string: string | null) => {
    /* eslint-disable-next-line */
    return matches(string || '', /^[ァ-ヾ 　〜ー−]+$/m)
}

export const checkPassCode = (string: string | null) => {
    return matches(
        string || '',
        /^(?=.*?[a-zA-Z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[!-~]+$/i
    )
}

export const checkMinLength = (string: string | null, length: number) => {
    return (string || '').length >= length
}

export const checkMaxLength = (string: string | null, length: number) => {
    return (string || '').length <= length
}

export const checkEqual = (string: string | null, target: string | null) => {
    return string === target
}
