import { convert } from './core.convert.js'
import { filter } from './core.filter.js'
import { reject } from './core.reject.js'

const core = {
    convert,
    filter,
    reject,
}

export { core }
