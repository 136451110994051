import { InjectionKey, reactive } from '@vue/composition-api'

export const STEP_INPUT = 1
export const STEP_CONFIRM = 2

export type FormType = {
    base_path?: string
    is_loading: boolean
    confirm: boolean
    result: boolean
    step: number
    has_confirm_button: boolean
    field: string
    url_input: string
    url_confirm: string
    url_result: string
    error_scroll_gap: number
    is_enable_recaptcha: number
    recaptcha_site_key: string
    form_wrap: HTMLElement | null
}

export default function store() {
    const state = reactive<FormType>({
        base_path: '',
        is_loading: false,
        confirm: false,
        result: false,
        step: STEP_INPUT,
        has_confirm_button: false,
        field: '',
        url_input: '',
        url_confirm: '',
        url_result: '',
        error_scroll_gap: 0,
        is_enable_recaptcha: 0,
        recaptcha_site_key: '',
        form_wrap: null,
    })

    return {
        // base_path
        get base_path() {
            return state.base_path
        },
        set base_path(base_path) {
            state.base_path = base_path
        },

        // is_loading
        get is_loading() {
            return state.is_loading
        },
        set is_loading(is_loading) {
            state.is_loading = is_loading
        },

        // confirm
        get confirm() {
            return state.confirm
        },
        set confirm(confirm) {
            state.confirm = confirm
        },

        // result
        get result() {
            return state.result
        },
        set result(result) {
            state.result = result
        },

        // step
        get step() {
            return state.step
        },
        set step(step) {
            state.step = step
        },

        // hasConfirmButton
        get has_confirm_button() {
            return state.has_confirm_button
        },
        set has_confirm_button(has_confirm_button) {
            state.has_confirm_button = has_confirm_button
        },

        // Field
        get field() {
            return state.field
        },

        // url_input
        get url_input() {
            return state.url_input
        },

        // url_confirm
        get url_confirm() {
            return state.url_confirm
        },

        // url_result
        get url_result() {
            return state.url_result
        },

        get error_scroll_gap() {
            return state.error_scroll_gap
        },

        get is_enable_recaptcha() {
            return state.is_enable_recaptcha
        },

        get recaptcha_site_key() {
            return state.recaptcha_site_key
        },

        get form_wrap() {
            return state.form_wrap
        },
        set form_wrap(form_wrap) {
            state.form_wrap = form_wrap
        },

        update(data: FormType) {
            state.field = data.field + ''
            state.url_input = data.url_input + ''
            state.url_confirm = data.url_confirm + ''
            state.url_result = data.url_result + ''
            state.error_scroll_gap = data.error_scroll_gap - 0
            state.is_enable_recaptcha = data.is_enable_recaptcha - 0
            state.recaptcha_site_key = data.recaptcha_site_key + ''
        },

        scrollTop() {
            if (state.form_wrap) {
                const rect = state.form_wrap.getBoundingClientRect()

                window.scrollTo({
                    top: window.pageYOffset + rect.top - state.error_scroll_gap,
                    left: 0,
                    behavior: 'smooth',
                })
            }
        },
    }
}

export type StoreType = ReturnType<typeof store>
export const StoreKey: InjectionKey<StoreType> = Symbol('FormType')
