import { InjectionKey, reactive } from '@vue/composition-api'

export type ErrorType = {
    messages?: string[]
}

export default function store() {
    const state = reactive<ErrorType>({
        messages: [],
    })

    return {
        // messages
        get messages() {
            return state.messages
        },

        update(data: ErrorType) {
            if (data.messages) {
                state.messages = [...data.messages]
            } else {
                state.messages = []
            }
        },
    }
}

export type StoreType = ReturnType<typeof store>
export const StoreKey: InjectionKey<StoreType> = Symbol('ErrorType')
