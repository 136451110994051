



















import Vue from 'vue'
import {
    defineComponent,
    inject,
    ref,
    Ref,
    computed,
    getCurrentInstance,
    watch,
    onMounted,
} from '@vue/composition-api'
import axios from 'axios'

// Store
import {
    StoreKey as InputStoreKey,
    StoreType as InputStoreType,
} from '@/store/Input'
import {
    StoreKey as FormStoreKey,
    StoreType as FormStoreType,
    STEP_CONFIRM,
} from '@/store/Form'
import {
    StoreKey as ErrorStoreKey,
    StoreType as ErrorStoreType,
} from '@/store/Error'
import { ComponentInstance } from '@vue/composition-api/dist/component'

const useSubmitHandler = (
    formStore: FormStoreType,
    inputStore: InputStoreType,
    errorStore: ErrorStoreType,
    formWrap: Ref<HTMLElement | null>,
    currentInstance: ComponentInstance | null
) => {
    return async () => {
        formStore.is_loading = true

        if (formStore.confirm || formStore.step === STEP_CONFIRM) {
            sendMailRequest(formStore, inputStore)
            return
        }

        if (!currentInstance || !currentInstance.$children.length) {
            formStore.is_loading = false
            return
        }

        const requestData = new FormData()
        const uploaders: Vue[] = []
        const validateRule: { [key: string]: any } = {}
        let firstErrorComponent: Vue | null = null

        const checkComponentData = (component: Vue) => {
            if (component.$data.hasOwnProperty('onValidate')) {
                if (
                    component.$data.onValidate() &&
                    firstErrorComponent === null
                ) {
                    firstErrorComponent = component
                }
            }

            if (component.$data.hasOwnProperty('get_type')) {
                const type = component.$data.get_type()
                const name = component.$data.get_name()

                switch (type) {
                    case 'back_button':
                    case 'checkbox_item':
                    case 'confirm_button':
                    case 'radio_item':
                    case 'select_option':
                    case 'submit_button':
                        break
                    case 'file_uploader':
                        {
                            uploaders.push(component)
                        }
                        break
                    case 'input':
                        {
                            const inputType = component.$data.get_input_type()
                            const value = component.$data.get_value()
                            requestData.append(name, value)

                            if (inputType === 'password') {
                                requestData.append(
                                    '_form_system_secure_fields[]',
                                    name
                                )
                            }
                        }
                        break
                    default: {
                        const value = component.$data.get_value()
                        if (Array.isArray(value)) {
                            value.map((v) => {
                                requestData.append(`${name}[]`, v)
                            })
                        } else {
                            requestData.append(name, value)
                        }
                        break
                    }
                }

                if (component.$data.hasOwnProperty('getValidateRule')) {
                    validateRule[name] = component.$data.getValidateRule()
                }
            }

            if (component.$children.length) {
                component.$children.map(checkComponentData)
            }
        }

        const wrapComponent = currentInstance.$children[0]
        wrapComponent.$children.map(checkComponentData)

        if (firstErrorComponent === null) {
            firstErrorComponent = wrapComponent
        }

        //
        if (!inputStore.is_submitable) {
            formStore.is_loading = false

            const pos = (() => {
                if (!firstErrorComponent) {
                    return new DOMRect(0, 0)
                }

                const refs = Object.keys(firstErrorComponent.$refs)
                if (refs.length) {
                    let $ref = firstErrorComponent.$refs[refs[0]]

                    if (Array.isArray($ref)) {
                        $ref = $ref[0]
                    }

                    try {
                        return ($ref as Element).getBoundingClientRect()
                    } catch (e) {
                        console.log(e)
                    }
                }

                return firstErrorComponent.$el.getBoundingClientRect()
            })()
            window.scrollTo({
                left: 0,
                top: window.pageYOffset + pos.top - formStore.error_scroll_gap,
                behavior: 'smooth',
            })
            return
        }

        // File upload
        if (uploaders.length) {
            let uploadedFiles: {
                name: string
                files: {
                    dropzone: Dropzone.DropzoneFile
                    result: { name: string }
                }[]
            }[] = []

            await Promise.all(
                uploaders.map((uploader) => {
                    return new Promise<{
                        name: string
                        files: {
                            dropzone: Dropzone.DropzoneFile
                            result: { name: string }
                        }[]
                    }>((resolve) => {
                        var files: {
                            dropzone: Dropzone.DropzoneFile
                            result: { name: string }
                        }[] = []

                        if (!uploader.$data.hasOwnProperty('dropzone')) {
                            return resolve()
                        }

                        const dropzone = uploader.$data.dropzone as Dropzone

                        if (!dropzone.files.length) {
                            return resolve()
                        }

                        dropzone.on('success', (dropzone, result) => {
                            files.push({
                                dropzone,
                                result: (result as any).data,
                            })
                        })
                        dropzone.on('queuecomplete', () => {
                            dropzone.off('queuecomplete')
                            uploadedFiles.push({
                                name: uploader.$props.name as string,
                                files,
                            })
                            return resolve()
                        })
                        dropzone.processQueue()
                    })
                })
            )

            uploadedFiles.map((field) => {
                let index = 1
                field.files.map((file) => {
                    requestData.append(
                        `_form_system_upload[${field.name}][${index}]`,
                        file.result.name
                    )

                    index += 1
                })
            })
        }

        const validateRuleJSON = JSON.stringify(validateRule)

        requestData.append(
            '_form_system_form_id',
            inputStore.form_id.toString()
        )
        requestData.append('_form_system_validate', validateRuleJSON)

        errorStore.update({})

        const token = await (async () => {
            if (formStore.is_enable_recaptcha) {
                return await createRecaptchaToken('check', formStore)
            } else {
                return ''
            }
        })()
        requestData.append('_form_system_recaptcha_token', token)

        const response = await axios
            .post(`${formStore.base_path}/api/check`, requestData, {
                withCredentials: true,
            })
            .catch((e) => {
                console.log(e)
            })

        if (!response || typeof response.data !== 'object') {
            formStore.is_loading = false
            formStore.scrollTop()
            window.alert('不明のエラーが発生しました')
            throw Error('Failed to post')
        }

        if (response.data.error) {
            formStore.is_loading = false
            errorStore.update(response.data.data)
            formStore.scrollTop()
            return
        }

        if (formStore.has_confirm_button) {
            if (formStore.url_confirm) {
                window.location.href = formStore.url_confirm
            } else {
                formStore.is_loading = false
                formStore.step = STEP_CONFIRM
                formStore.scrollTop()

                if (
                    typeof (window as any)._form_system_confirm === 'function'
                ) {
                    ;(window as any)._form_system_confirm(
                        response.data.data.data
                    )
                }
            }
        } else {
            sendMailRequest(formStore, inputStore)
        }
    }
}

const sendMailRequest = async (
    formStore: FormStoreType,
    inputStore: InputStoreType
) => {
    const requestData = new FormData()
    requestData.append('_form_system_form_id', inputStore.form_id.toString())

    const token = await (async () => {
        if (formStore.is_enable_recaptcha) {
            return await createRecaptchaToken('submit', formStore)
        } else {
            return ''
        }
    })()
    requestData.append('_form_system_recaptcha_token', token)

    const response = await axios
        .post(`${formStore.base_path}/api/send`, requestData, {
            withCredentials: true,
        })
        .catch((e) => {
            console.log(e)
        })

    if (!response || typeof response.data !== 'object' || response.data.error) {
        formStore.is_loading = false
        window.alert('メールの送信に失敗しました')
        throw Error('Failed to post')
    }

    if (typeof (window as any)._form_system_finish === 'function') {
        ;(window as any)._form_system_finish(function () {
            window.location.href = formStore.url_result
        }, response.data.data.data)
    } else {
        window.location.href = formStore.url_result
    }
}

const assignAttributesValue = (
    inputStore: InputStoreType,
    data: { [key: string]: string },
    currentInstance: ComponentInstance | null
) => {
    if (!currentInstance) {
        return
    }
    currentInstance.$nextTick().then(() => {
        ;(window as any)._update_from_store = () => {
            if (!currentInstance.$children.length) {
                return
            }

            const assignValues: { [key: string]: string } = {}
            const checkComponentData = (component: Vue) => {
                if (!component.$data.hasOwnProperty('get_name')) {
                    if (component.$children.length) {
                        component.$children.map(checkComponentData)
                    }
                    return
                }

                const name = component.$data.get_name() as string
                if (!name) {
                    if (component.$children.length) {
                        component.$children.map(checkComponentData)
                    }
                    return
                }

                const key = Object.keys(data).find((key) => {
                    return key.toUpperCase() === name.toUpperCase()
                })
                if (!key) {
                    if (component.$children.length) {
                        component.$children.map(checkComponentData)
                    }
                    return
                }

                assignValues[name] = data[key]
                if (component.$children.length) {
                    component.$children.map(checkComponentData)
                }
            }

            const wrapComponent = currentInstance.$children[0]
            wrapComponent.$children.map(checkComponentData)
            inputStore.updateNegative(assignValues)

            const updateComponentData = (component: Vue) => {
                if (component.$data.hasOwnProperty('updateHandler')) {
                    component.$data.updateHandler()
                }

                if (component.$children.length) {
                    component.$children.map(updateComponentData)
                }
            }
            wrapComponent.$children.map(updateComponentData)
        }
        ;(window as any)._update_from_store()

        if (typeof (window as any)._form_loaded === 'function') {
            ;(window as any)._form_loaded()
        }
    })
}

declare const grecaptcha: {
    ready: (Function) => void
    execute: (key: string, { action: string }) => Promise<string>
}

const createRecaptchaToken = async (
    action: string,
    formStore: FormStoreType
) => {
    return new Promise<string>((resolve) => {
        grecaptcha.ready(() => {
            grecaptcha
                .execute(formStore.recaptcha_site_key, { action })
                .then((token) => {
                    resolve(token)
                })
        })
    })
}

export default defineComponent({
    setup(_props, { root }) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const errorStore = inject(ErrorStoreKey)
        if (!errorStore) {
            throw new Error(`${ErrorStoreKey} is not provided`)
        }

        const formWrap = ref<HTMLElement>(null)
        const compiled = computed(() => {
            return Vue.compile(`<fragment>${formStore.field}</fragment>`)
        })

        const currentInstance = getCurrentInstance()
        const submitHandler = useSubmitHandler(
            formStore,
            inputStore,
            errorStore,
            formWrap,
            currentInstance
        )

        watch(compiled, () => {
            assignAttributesValue(inputStore, root.$data, currentInstance)
        })

        onMounted(() => {
            if (formWrap.value) {
                formStore.form_wrap = formWrap.value
            }
        })

        return {
            formStore,
            inputStore,
            errorStore,
            formWrap,
            submitHandler,
            compiled,
        }
    },
})
