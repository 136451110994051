




import { defineComponent, inject, onMounted, ref } from '@vue/composition-api'

// Store
import { StoreKey as FormStoreKey } from '@/store/Form'

// Util
import { prepareField } from '@/ts/util/field'

type Props = {
    value: string
    selected: string | boolean
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        value: { type: String, required: false },
        selected: { type: [String, Boolean], default: false },
    },
    setup(props, { root, parent }) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }
        const currentSelected = ref<boolean>(false)

        const commonFn = prepareField({
            type: 'select_option',
            name: '',
            formStore,
        })

        onMounted(() => {
            if (props.selected === false) {
                return
            }
            currentSelected.value = true

            root.$nextTick(() => {
                if (parent) {
                    parent.$data.changeHandler()
                }
            })
        })

        return { ...commonFn, currentSelected }
    },
})
