

























import {
    defineComponent,
    ref,
    inject,
    computed,
    onMounted,
} from '@vue/composition-api'

// Store
import { StoreKey as FormStoreKey } from '@/store/Form'
import { StoreKey as InputStoreKey } from '@/store/Input'

// Util
import { prepareField } from '@/ts/util/field'
import { convertFull, convertHalf, removeHyphen } from '@/ts/util/convert'
import {
    checkRequire,
    checkHiragana,
    checkKatakana,
    checkPassCode,
    checkAlphanumeric,
    checkEqual,
    checkMinLength,
    checkMaxLength,
    checkEmail,
} from '@/ts/util/validate'
import YubinBango from '@/ts/yubinbango-core'

type Props = {
    name: string
    required: boolean | string
    requiredMessage: string
    type: string
    id: string
    classname: string
    placeholder: string
    size: string
    checkHiragana: boolean | string
    checkHiraganaMessage: string
    checkKatakana: boolean | string
    checkKatakanaMessage: string
    checkAlphanumeric: boolean | string
    checkAlphanumericMessage: string
    checkPasscode: boolean | string
    checkPasscodeMessage: string
    checkEqual: boolean | string
    checkEqualMessage: string
    checkMinlength: string
    checkMinlengthMessage: string
    checkMaxlength: string
    checkMaxlengthMessage: string
    convertToFull: boolean | string
    convertToHalf: boolean | string
    removeHyphen: boolean | string
    zip: boolean | string
    zipRegion: string
    zipLocality: string
    zipStreet: string
    zipExtended: string
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        required: { type: [String, Boolean], required: false },
        requiredMessage: { type: String, required: false },
        type: { type: String, required: false },
        id: { type: String, required: false },
        classname: { type: String, required: false },
        placeholder: { type: String, required: false },
        size: { type: String, required: false },
        checkHiragana: { type: [String, Boolean], required: false },
        checkHiraganaMessage: { type: String, required: false },
        checkKatakana: { type: [String, Boolean], required: false },
        checkKatakanaMessage: { type: String, required: false },
        checkAlphanumeric: { type: [String, Boolean], required: false },
        checkAlphanumericMessage: { type: String, required: false },
        checkPasscode: { type: [String, Boolean], required: false },
        checkPasscodeMessage: { type: String, required: false },
        checkEqual: { type: [String, Boolean], required: false },
        checkEqualMessage: { type: String, required: false },
        checkMinlength: { type: String, required: false },
        checkMinlengthMessage: { type: String, required: false },
        checkMaxlength: { type: String, required: false },
        checkMaxlengthMessage: { type: String, required: false },
        convertToFull: { type: [String, Boolean], required: false },
        convertToHalf: { type: [String, Boolean], required: false },
        removeHyphen: { type: [String, Boolean], required: false },
        zip: { type: [String, Boolean], required: false },
        zipRegion: { type: String, required: false },
        zipLocality: { type: String, required: false },
        zipStreet: { type: String, required: false },
        zipExtended: { type: String, required: false },
    },
    setup(props: Props) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const commonFn = prepareField({
            type: 'input',
            name: props.name,
            inputStore,
            formStore,
        })
        const get_input_type = () => {
            return props.type
        }

        const target = ref<HTMLInputElement>(null)

        const updateHandler = () => {
            if (!target.value) {
                return
            }

            target.value.value = inputStore.get_value(props.name)
        }
        onMounted(updateHandler)

        const inputHandler = () => {
            if (!target.value) {
                return
            }

            inputStore.set_value(props.name, target.value.value)

            if (props.zip !== false) {
                new YubinBango.Core(inputStore.get_value(props.name), function (
                    addr
                ) {
                    if (addr.region_id) {
                        if (props.zipRegion) {
                            inputStore.set_value(props.zipRegion, '')
                        }
                        if (props.zipLocality) {
                            inputStore.set_value(props.zipLocality, '')
                        }
                        if (props.zipStreet) {
                            inputStore.set_value(props.zipStreet, '')
                        }
                        if (props.zipExtended) {
                            inputStore.set_value(props.zipExtended, '')
                        }

                        if (props.zipRegion) {
                            const origin = inputStore.get_value(props.zipRegion)
                            inputStore.set_value(
                                props.zipRegion,
                                `${origin}${addr.region}`
                            )
                        }
                        if (props.zipLocality) {
                            const origin = inputStore.get_value(
                                props.zipLocality
                            )
                            inputStore.set_value(
                                props.zipLocality,
                                `${origin}${addr.locality}`
                            )
                        }
                        if (props.zipStreet) {
                            const origin = inputStore.get_value(props.zipStreet)
                            inputStore.set_value(
                                props.zipStreet,
                                `${origin}${addr.street}`
                            )
                        }
                        if (props.zipExtended) {
                            const origin = inputStore.get_value(
                                props.zipExtended
                            )
                            inputStore.set_value(
                                props.zipExtended,
                                `${origin}${addr.extended}`
                            )
                        }

                        ;(window as any)._update_from_store()
                    }
                })
            }
        }

        const errors = ref<string[]>([])
        const blurHandler = () => {
            if (!target.value) {
                return
            }

            let original = target.value.value

            if (props.convertToFull) {
                original = convertFull(original)
            }
            if (props.convertToHalf) {
                original = convertHalf(original)
            }
            if (props.removeHyphen) {
                original = removeHyphen(original)
            }

            if (original !== target.value.value) {
                target.value.value = original
            }
            inputStore.set_value(props.name, original)

            onValidate()
        }

        const onValidate = () => {
            const value = inputStore.get_value(props.name)

            // バリデーション
            let result = false
            errors.value = []
            const requiredCheck = checkRequire(value)
            if (props.required !== false) {
                if (!requiredCheck) {
                    result = true

                    const message = props.requiredMessage || '入力してください'
                    errors.value.push(message)
                }
            }
            if (props.checkHiragana !== false) {
                const checkResult = checkHiragana(value)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkHiraganaMessage ||
                        'ひらがなで入力してください'
                    errors.value.push(message)
                }
            }
            if (props.checkKatakana !== false) {
                const checkResult = checkKatakana(value)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkKatakanaMessage ||
                        'カタカナで入力してください'
                    errors.value.push(message)
                }
            }
            if (props.checkAlphanumeric !== false) {
                const checkResult = checkAlphanumeric(value)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkAlphanumericMessage ||
                        '半角英数で入力してください'
                    errors.value.push(message)
                }
            }
            if (props.checkPasscode !== false) {
                const checkResult = checkPassCode(value)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkPasscodeMessage ||
                        '半角英数記号で入力してください'
                    errors.value.push(message)
                }
            }
            if (props.checkEqual !== false) {
                const target = inputStore.get_value(props.checkEqual)
                const checkResult = checkEqual(value, target)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkEqualMessage || '入力内容が一致していません'
                    errors.value.push(message)
                }
            }
            if (parseInt(props.checkMinlength || '0') > 0) {
                const minLength = parseInt(props.checkMinlength || '0')
                const checkResult = checkMinLength(value, minLength)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkMinlengthMessage ||
                        `${minLength}文字以上で入力ください`
                    errors.value.push(message)
                }
            }
            if (parseInt(props.checkMaxlength || '0') > 0) {
                const maxLength = parseInt(props.checkMaxlength || '0')
                const checkResult = checkMaxLength(value, maxLength)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkMaxlengthMessage ||
                        `${maxLength}文字以内で入力ください`
                    errors.value.push(message)
                }
            }
            if (props.type === 'email') {
                const checkResult = checkEmail(value)

                if (requiredCheck && !checkResult) {
                    result = true

                    const message = `メールアドレスを正しく入力ください`
                    errors.value.push(message)
                }
            }

            inputStore.set_error(props.name, result)
            return result
        }

        const getValidateRule = () => {
            return {
                required: props.required ? true : false,
                checkHiragana: props.checkHiragana ? true : false,
                checkKatakana: props.checkKatakana ? true : false,
                checkAlphanumeric: props.checkAlphanumeric ? true : false,
                checkPasscode: props.checkPasscode ? true : false,
            }
        }

        const classnames = computed(() => {
            const errors = inputStore.errors

            const result: { [key: string]: boolean } = {}
            ;(props.classname || '').split(' ').map((key) => {
                result[key] = true
            })
            result['is-invalid'] =
                errors.hasOwnProperty(props.name) && errors[props.name]

            return result
        })
        return {
            blurHandler,
            updateHandler,
            inputHandler,
            target,
            errors,
            classnames,
            ...commonFn,
            get_input_type,
            onValidate,
            getValidateRule,
        }
    },
})
