var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fragment", [
    _vm.isShow
      ? _c(
          "button",
          {
            class: _vm.classname,
            attrs: {
              type: "submit",
              name: _vm.name,
              id: _vm.id,
              disabled: !_vm.isSubmitable
            }
          },
          [_vm._t("default")],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.isShow
      ? _c("span", {
          staticClass: "c-loading-animation",
          class: _vm.loadingClass
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }