





















import { defineComponent, computed, inject } from '@vue/composition-api'

// Store
import { StoreKey as FormStoreKey, STEP_INPUT } from '@/store/Form'
import { StoreKey as InputStoreKey } from '@/store/Input'

// Util
import { prepareField } from '@/ts/util/field'

type Props = {
    name: string
    id: string
    classname: string
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        id: { type: String, required: false },
        classname: { type: String, required: false },
    },
    setup(props: Props) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }
        formStore.has_confirm_button = true

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const commonFn = prepareField({
            type: 'confirm_button',
            name: props.name,
            formStore,
        })

        const isShow = computed(() => {
            return !formStore.confirm && formStore.step === STEP_INPUT
        })

        const isSubmitable = computed(() => {
            return inputStore.is_submitable && !formStore.is_loading
        })

        const loadingClass = computed(() => {
            return { 'is-show': formStore.is_loading }
        })

        return { isShow, isSubmitable, loadingClass, ...commonFn }
    },
})
