var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classname },
    [
      _vm.separateLabel !== false
        ? [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selected,
                  expression: "selected"
                }
              ],
              class: _vm.inputClass,
              attrs: {
                type: "checkbox",
                name: _vm.name,
                id: _vm.id,
                value: "1",
                disabled: _vm.readonly
              },
              domProps: {
                checked: Array.isArray(_vm.selected)
                  ? _vm._i(_vm.selected, "1") > -1
                  : _vm.selected
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.selected,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = "1",
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selected = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selected = $$c
                    }
                  },
                  _vm.changeHandler
                ]
              }
            }),
            _vm._v(" "),
            _c(
              "label",
              { class: _vm.labelClass, attrs: { for: _vm.id } },
              [_vm._t("default")],
              2
            )
          ]
        : _c(
            "label",
            { class: _vm.labelClass },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selected,
                    expression: "selected"
                  }
                ],
                class: _vm.inputClass,
                attrs: {
                  type: "checkbox",
                  name: _vm.name,
                  id: _vm.id,
                  value: "1",
                  disabled: _vm.readonly
                },
                domProps: {
                  checked: Array.isArray(_vm.selected)
                    ? _vm._i(_vm.selected, "1") > -1
                    : _vm.selected
                },
                on: {
                  change: [
                    function($event) {
                      var $$a = _vm.selected,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "1",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.selected = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.selected = $$c
                      }
                    },
                    _vm.changeHandler
                  ]
                }
              }),
              _vm._v(" "),
              _vm._t("default")
            ],
            2
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }