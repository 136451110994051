// Corejs
import 'core-js/es/symbol'
import 'core-js/es/promise'
import 'core-js/es/array/find'
import 'core-js/es/array/from'

// Polifill
import smoothscroll from 'smoothscroll-polyfill'
smoothscroll.polyfill()

// Flatpickr
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import { Japanese } from 'flatpickr/dist/l10n/ja.js'
flatpickr.localize(Japanese)

// Dropzone
import Dropzone from 'dropzone'
Dropzone.autoDiscover = false
import 'dropzone/dist/dropzone.css'

// Other css
import '@/scss/form.scss'

// Vue
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import { Plugin } from 'vue-fragment'

// Composition API
Vue.use(VueCompositionApi)
Vue.use(Plugin)

// nl2br
import Nl2br from 'vue-nl2br'
Vue.component('nl2br', Nl2br)

const root_element = document.querySelector<HTMLElement>('embed-form')
const isConfirm = root_element && root_element.dataset.confirm !== undefined
const isResult = root_element && root_element.dataset.result !== undefined

// Field Components
import FieldInput from '@/component/form/Field/Input.vue'
import FieldTextarea from '@/component/form/Field/Textarea.vue'
import FieldSelect from '@/component/form/Field/Select.vue'
import FieldSelectOption from '@/component/form/Field/SelectOption.vue'
import FieldRadio from '@/component/form/Field/Radio.vue'
import FieldRadioItem from '@/component/form/Field/RadioItem.vue'
import FieldCheckbox from '@/component/form/Field/Checkbox.vue'
import FieldCheckboxItem from '@/component/form/Field/CheckboxItem.vue'
import FieldAcceptance from '@/component/form/Field/Acceptance.vue'
import FieldDatePicker from '@/component/form/Field/DatePicker.vue'
import FieldFileUploader from '@/component/form/Field/FileUploader.vue'

// Confirm component
// import FieldConfirmCommon from '@/component/form/Field/Confirm/Common.vue'
// import FieldConfirmHide from '@/component/form/Field/Confirm/Hide.vue'

// Common component
import FieldCondition from '@/component/form/Field/Condition.vue'
import FieldBackButton from '@/component/form/Field/BackButton.vue'
import FieldConfirmButton from '@/component/form/Field/ConfirmButton.vue'
import FieldSubmitButton from '@/component/form/Field/SubmitButton.vue'

// if (!isConfirm) {
Vue.component('FieldInput', FieldInput)
Vue.component('FieldTextarea', FieldTextarea)
Vue.component('FieldSelect', FieldSelect)
Vue.component('FieldSelectOption', FieldSelectOption)
Vue.component('FieldRadio', FieldRadio)
Vue.component('FieldRadioItem', FieldRadioItem)
Vue.component('FieldCheckbox', FieldCheckbox)
Vue.component('FieldCheckboxItem', FieldCheckboxItem)
Vue.component('FieldAcceptance', FieldAcceptance)
Vue.component('FieldDatePicker', FieldDatePicker)
Vue.component('FieldFileUploader', FieldFileUploader)
// } else {
//     Vue.component('FieldInput', FieldConfirmCommon)
//     Vue.component('FieldTextarea', FieldConfirmCommon)
//     Vue.component('FieldSelect', FieldConfirmCommon)
//     Vue.component('FieldSelectOption', FieldConfirmCommon)
//     Vue.component('FieldRadio', FieldConfirmCommon)
//     Vue.component('FieldRadioItem', FieldConfirmCommon)
//     Vue.component('FieldCheckbox', FieldConfirmCommon)
//     Vue.component('FieldCheckboxItem', FieldConfirmCommon)
//     Vue.component('FieldAcceptance', FieldConfirmCommon)
//     Vue.component('FieldDatePicker', FieldConfirmCommon)
//     Vue.component('FieldFileUploader', FieldConfirmCommon)
// }

Vue.component('FieldCondition', FieldCondition)
Vue.component('FieldBackButton', FieldBackButton)
Vue.component('FieldConfirmButton', FieldConfirmButton)
Vue.component('FieldSubmitButton', FieldSubmitButton)

Vue.config.productionTip = true

import App from '@/component/form/App.vue'

if (root_element) {
    const data: { [key: string]: string } = {}

    if (root_element.hasAttributes()) {
        const length = root_element.attributes.length
        for (let i = 0; i < length; i += 1) {
            const attr = root_element.attributes[i]
            data[attr.name] = attr.value
        }
    }

    new Vue({
        data,
        render: (h) =>
            h(App, {
                props: {
                    formId: parseInt(root_element.dataset.formId || '0'),
                    basePath: root_element.dataset.basePath || '',
                    confirm: isConfirm,
                    result: isResult,
                },
            }),
    }).$mount(root_element)
}
