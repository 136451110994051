














import { defineComponent, computed, inject } from '@vue/composition-api'

// Store
import {
    StoreKey as FormStoreKey,
    STEP_CONFIRM,
    STEP_INPUT,
} from '@/store/Form'

// Util
import { prepareField } from '@/ts/util/field'

type Props = {
    name: string
    id: string
    classname: string
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        id: { type: String, required: false },
        classname: { type: String, required: false },
    },
    setup(props: Props) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const isShow = computed(() => {
            return formStore.confirm || formStore.step === STEP_CONFIRM
        })

        const commonFn = prepareField({
            type: 'back_button',
            name: props.name,
            formStore,
        })

        const clickHandler = () => {
            if (formStore.url_confirm) {
                window.location.href = formStore.url_input
            } else {
                formStore.step = STEP_INPUT
                formStore.scrollTop()
            }
        }

        const isSubmitable = computed(() => {
            return !formStore.is_loading
        })

        return { isShow, clickHandler, isSubmitable, ...commonFn }
    },
})
