const { moji } = require('../../lib/moji/docs/index.js')

export const convertFull = (string: string) => {
    return moji(string).convert('HEtoZE').convert('HKtoZK').toString()
}

export const convertHalf = (string: string) => {
    return moji(string).convert('ZEtoHE').convert('ZKtoHK').toString()
}

export const removeHyphen = (string: string) => {
    moji.addMojisyu({
        HP: {
            regexp: /[-−―‐ー➖]/g,
            list: ['-', '−', '―', '‐', 'ー', '➖'],
        },
    })
    return moji(string).reject('HP').toString()
}
