
























import {
    defineComponent,
    ref,
    inject,
    computed,
    onMounted,
} from '@vue/composition-api'

// Store
import { StoreKey as FormStoreKey } from '@/store/Form'
import { StoreKey as InputStoreKey } from '@/store/Input'

// Util
import { prepareField } from '@/ts/util/field'
import { convertFull, convertHalf, removeHyphen } from '@/ts/util/convert'
import {
    checkRequire,
    checkHiragana,
    checkKatakana,
    checkPassCode,
    checkAlphanumeric,
} from '@/ts/util/validate'

type Props = {
    name: string
    required: boolean | string
    requiredMessage: string
    id: string
    classname: string
    placeholder: string
    cols: string
    rows: string
    checkHiragana: boolean | string
    checkHiraganaMessage: string
    checkKatakana: boolean | string
    checkKatakanaMessage: string
    checkAlphanumeric: boolean | string
    checkAlphanumericMessage: string
    checkPasscode: boolean | string
    checkPasscodeMessage: string
    convertToFull: boolean | string
    convertToHalf: boolean | string
    removeHyphen: boolean | string
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        name: { type: String, required: false },
        required: { type: [String, Boolean], required: false },
        requiredMessage: { type: String, required: false },
        id: { type: String, required: false },
        classname: { type: String, required: false },
        placeholder: { type: String, required: false },
        cols: { type: String, required: false },
        rows: { type: String, required: false },
        checkHiragana: { type: [String, Boolean], required: false },
        checkHiraganaMessage: { type: String, required: false },
        checkKatakana: { type: [String, Boolean], required: false },
        checkKatakanaMessage: { type: String, required: false },
        checkAlphanumeric: { type: [String, Boolean], required: false },
        checkAlphanumericMessage: { type: String, required: false },
        checkPasscode: { type: [String, Boolean], required: false },
        checkPasscodeMessage: { type: String, required: false },
        convertToFull: { type: [String, Boolean], required: false },
        convertToHalf: { type: [String, Boolean], required: false },
        removeHyphen: { type: [String, Boolean], required: false },
    },
    setup(props: Props) {
        const formStore = inject(FormStoreKey)
        if (!formStore) {
            throw new Error(`${FormStoreKey} is not provided`)
        }

        const inputStore = inject(InputStoreKey)
        if (!inputStore) {
            throw new Error(`${InputStoreKey} is not provided`)
        }

        const commonFn = prepareField({
            type: 'textarea',
            name: props.name,
            inputStore,
            formStore,
        })

        const target = ref<HTMLInputElement>(null)
        const updateHandler = () => {
            if (!target.value) {
                return
            }

            target.value.value = inputStore.get_value(props.name)
        }
        onMounted(updateHandler)

        const inputHandler = () => {
            if (!target.value) {
                return
            }

            inputStore.set_value(props.name, target.value.value)
        }

        const errors = ref<string[]>([])
        const blurHandler = () => {
            if (!target.value) {
                return
            }

            let original = target.value.value

            if (props.convertToFull) {
                original = convertFull(original)
            }
            if (props.convertToHalf) {
                original = convertHalf(original)
            }
            if (props.removeHyphen) {
                original = removeHyphen(original)
            }

            if (original !== target.value.value) {
                target.value.value = original
            }
            inputStore.set_value(props.name, original)

            // バリデーション
            onValidate()
        }

        const onValidate = () => {
            const value = inputStore.get_value(props.name)

            // バリデーション
            let result = false
            errors.value = []
            const requiredCheck = checkRequire(value)
            if (props.required !== false) {
                if (!requiredCheck) {
                    result = true

                    const message = props.requiredMessage || '入力してください'
                    errors.value.push(message)
                }
            }
            if (props.checkHiragana !== false) {
                const checkResult = checkHiragana(value)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkHiraganaMessage ||
                        'ひらがなで入力してください'
                    errors.value.push(message)
                }
            }
            if (props.checkKatakana !== false) {
                const checkResult = checkKatakana(value)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkKatakanaMessage ||
                        'カタカナで入力してください'
                    errors.value.push(message)
                }
            }
            if (props.checkAlphanumeric !== false) {
                const checkResult = checkAlphanumeric(value)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkAlphanumericMessage ||
                        '半角英数で入力してください'
                    errors.value.push(message)
                }
            }
            if (props.checkPasscode !== false) {
                const checkResult = checkPassCode(value)
                if (requiredCheck && !checkResult) {
                    result = true

                    const message =
                        props.checkPasscodeMessage ||
                        '半角英数記号で入力してください'
                    errors.value.push(message)
                }
            }

            inputStore.set_error(props.name, result)
            return result
        }

        const getValidateRule = () => {
            return {
                required: props.required ? true : false,
                checkHiragana: props.checkHiragana ? true : false,
                checkKatakana: props.checkKatakana ? true : false,
                checkAlphanumeric: props.checkAlphanumeric ? true : false,
                checkPasscode: props.checkPasscode ? true : false,
            }
        }

        const classnames = computed(() => {
            const errors = inputStore.errors

            const result: { [key: string]: boolean } = {}
            ;(props.classname || '').split(' ').map((key) => {
                result[key] = true
            })
            result['is-invalid'] =
                errors.hasOwnProperty(props.name) && errors[props.name]

            return result
        })
        return {
            updateHandler,
            inputHandler,
            blurHandler,
            target,
            errors,
            classnames,
            ...commonFn,
            onValidate,
            getValidateRule,
        }
    },
})
